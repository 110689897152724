<template>
  <div>
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-row">
            <div class="col-lg-12">
              <div class="form-row mb-1">
                <div class="col-lg-4 mb-1 mb-lg-0">
                  <label>Filter by user</label>
                  <v-select
                    v-model="filter.user_uuid"
                    :options="users"
                    :reduce="option => option.uuid"
                    @search="onSearchUser"
                  >
                    <template slot="no-options">
                      Type user name..
                    </template>
                  </v-select>
                </div>
                <div class="col-lg-4 mb-1 mb-lg-0">
                  <label>Filter by gift voucher</label>
                  <v-select
                    v-model="filter.gift_voucher_uuid"
                    :options="vouchers"
                    :reduce="option => option.uuid"
                    @search="onSearchVoucher"
                  >
                    <template slot="no-options">
                      Type gift voucher name..
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive-sm" style="overflow: scroll;">
      <table class="table b-table">
        <thead>
          <tr>
            <th>Kode</th>
            <th>Kadaluwarsa (Bulan)</th>
            <th>Dikirim Kepada</th>
            <th>Pesan</th>
            <th>Status Pembayaran</th>
            <th>Nilai Voucher</th>
            <th>Status Redeem</th>
            <th>Pengirim</th>
            <th>Pengirim (Alias)</th>
            <th>Gift Voucher Design</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoadingComponent">
            <td
              colspan="10"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in resultData.data"
            v-else
            :key="item.id"
          >
            <td>{{ item.code ? item.code : '-' }}</td>
            <td>{{ item.expire_month ? item.expire_month + ' Bulan' : '-' }}</td>
            <td>
              <span>{{ item.to_name ? item.to_name : '-' }}</span><br>
              <span>{{ item.to_email ? item.to_email : '-' }}</span><br>
              <span>{{ item.to_phone_number ? item.to_phone_number : '-' }}</span>
            </td>
            <td>
              {{ item.message ? item.message : '-' }}
            </td>
            <td>
              <span
                class="badge text-capitalize badge-pill"
                :class="
                  item.payment_status == 'success'
                    ? 'badge-light-success'
                    : item.payment_status == 'pending' ? 'badge-light-warning' : 'badge-light-danger'
                "
              >{{ item.payment_status }}</span>
            </td>
            <td>
              {{ item.amount | formatAmount }}
            </td>
            <td>
              <span
                class="badge text-capitalize badge-pill"
                :class="
                  item.is_redeemed == true
                    ? 'text-success'
                    : 'text-danger'
                "
              >
                <feather-icon
                  :icon="item.is_redeemed == true ? 'CheckCircleIcon' : 'XCircleIcon'"
                  class="mr-75"
                />
              </span>
            </td>
            <td>
              <a
                v-if="item.user"
                class="text-primary"
                @click="goToDetail('users.show', item.user.uuid)"
              >
                {{ item.user && item.user.name !== null ? item.user.name : '-' }}<br><small>{{ item.user && item.user.email !== null ? item.user.email : '-' }}</small>
              </a>
            </td>
            <td>{{ item.sender_name ? item.sender_name : '-' }}</td>
            <td>{{ item.gift_voucher && item.gift_voucher.name !== null ? item.gift_voucher.name : '-' }}</td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="6"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="resultData.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col mb-1">
            <small>Showing {{ resultData.from }} to {{ resultData.to }} from {{ resultData.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="resultData"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import vSelect from 'vue-select'
import {
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
    vSelect,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    result: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
    },
    value: {
      type: String,
    },
    getData: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value
    },
    result(value) {
      this.resultData = value
    },
    filter: {
      handler(value) {
        this.$emit('filter', value)
      },
      deep: true,
    },
  },
  data() {
    return {
      isLoadingComponent: true,
      users: [],
      vouchers: [],
      resultData: {},
      filter: {
        user_uuid: '',
        gift_voucher_uuid: '',
      },
    }
  },
  methods: {
    goToDetail(url, id) {
      this.$router.push({ name: url, params: { id } })
    },
    onSearchUser(search, loading) {
      if (search.length) {
        loading(true)
        this.searchUser(loading, search, this)
      }
    },
    searchUser: _.debounce((loading, search, vm) => {
      vm.$http.get(`/admin/users?type=normal&per_page=10&keyword=${escape(search)}`)
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.users = []
          response.data.data.data.forEach(user => {
            vm.users.push({
              uuid: user.uuid,
              label: user.name,
            })
          })
          loading(false)
        })
    }, 300),
    onSearchVoucher(search, loading) {
      if (search.length) {
        loading(true)
        this.searchVoucher(loading, search, this)
      }
    },
    searchVoucher: _.debounce((loading, search, vm) => {
      vm.$http.get(`/admin/voucher-design?per_page=10&keyword=${escape(search)}`)
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.vouchers = []
          response.data.data.data.forEach(voucher => {
            vm.vouchers.push({
              uuid: voucher.uuid,
              label: voucher.name,
            })
          })
          loading(false)
        })
    }, 300),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
