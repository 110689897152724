<template>
  <b-card-code
    no-body
    title="Daftar Misi"
  >
    <ListHistoryOrder
      :result="result"
      :is-loading="isLoading"
      :get-data="getData"
      @filter="getFilter($event)"
    />
  </b-card-code>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import ListHistoryOrder from '@/components/GiftVoucher/HistoryOrder/ListHistoryOrder.vue'

export default {
  title() {
    return 'History Order'
  },
  components: {
    BCardCode,
    ListHistoryOrder,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      isLoading: true,
      result: {},
      filter: {
        user_uuid: '',
      },
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getFilter(value) {
      this.filter = value || ''
    },
    getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/order-voucher', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
.table > tbody > tr > td {
  max-width: 300px;
}
</style>
